<template>
  <v-card class="cursor pa-2" @click="editTask" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title class="py-0 mb-2 no-flex-wrap">
        <v-spacer></v-spacer>
        <actions-menu
          :canEdit="!task.completed"
          :canArchive="task.completed && !task.archived"
          :canClone="task.completed && task.archived"
          @edit="editTask"
          @archive="archiveTask"
          @clone="copyTask"
          @delete="deleteTask"
        ></actions-menu>
      </v-card-title>
      <v-row align="center" justify="center">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="task.completed"
            text
            icon
            large
            color="app-green"
            :disabled="canCopy"
            @click.stop="reopenTask()"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            large
            icon
            color="app-green"
            @click.stop="completeTask()"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="pt-0 pl-md-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 pt-0 pb-2" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-1 pb-0">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <gold-label :gold="task.gold" :text-color="goldColorText"></gold-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmModal from '@/components/shared/ConfirmModal';
import ManageTaskModal from './ManageTaskModal';
import ActionsMenu from '../shared/ActionsMenu.vue';
import GoldLabel from '../shared/GoldLabel';
import TaskTags from './TaskTags';
import AddResultModal from './AddResultModal';
import {
  COMPLETE_TASK,
  REOPEN_TASK,
  ARCHIVE_TASK,
  COPY_TASK,
  DELETE_TASK
} from '@/store/tasks/types';
import multiNotificationsMixin from '../../mixins/multi.notifications.mixin';
import tasksGoldColorMixin from '../../mixins/task.gold.color.mixin';

export default {
  name: 'task',
  props: {
    task: Object
  },
  components: {
    ActionsMenu,
    TaskTags,
    GoldLabel
  },
  mixins: [multiNotificationsMixin, tasksGoldColorMixin],
  computed: {
    canCopy() {
      return this.task.completed && this.task.archived;
    }
  },
  methods: {
    openResultModal() {
      return this.$root.$modal.show(AddResultModal, {
        task: this.task
      });
    },
    async completeTask() {
      if (this.task.requireResult) {
        const result = await this.openResultModal();

        if (!result) return;

        this.task.result = result;
      }

      const { diff } = await this.$store.dispatch(COMPLETE_TASK, this.task);

      this.notifySuccess(diff, 'users.stats.success');
    },
    async reopenTask() {
      const { diff } = await this.$store.dispatch(REOPEN_TASK, this.task);

      this.notifyFailure(diff, 'users.stats.failure');
    },
    async archiveTask() {
      await this.$store.dispatch(ARCHIVE_TASK, this.task);
      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskArchived')
      });
    },
    async copyTask() {
      await this.$store.dispatch(COPY_TASK, this.task.id);
      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskCloned')
      });
    },
    editTask() {
      this.$root.$modal.show(ManageTaskModal, {
        selectedTask: { ...this.task }
      });
    },
    async deleteTask() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteTaskConfirm')
      });

      if (!confirmed) return;

      this.$store.dispatch(DELETE_TASK, this.task);
    }
  }
};
</script>
