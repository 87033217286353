<template>
  <div class="mt-5">
    <h3 class="subtitle text-center mb-3 font-weight-bold black--text">
      {{ question.text[currentUser.lang] }}
    </h3>
    <v-row justify="center">
      <v-radio-group v-model="selectedAnswer" row class="pt-5">
        <v-radio
          color="app-green"
          :label="$t('common.true')"
          :value="true"
          :disabled="questionAnswered"
        ></v-radio>
        <v-radio
          color="app-green"
          :label="$t('common.false')"
          :value="false"
          :disabled="questionAnswered"
        ></v-radio>
      </v-radio-group>
    </v-row>
    <h3 v-if="message.text" :class="message.color" class="text-center mt-5">
      <v-icon :class="message.color" class="mr-2">
        {{ message.icon }}
      </v-icon>
      <span>{{ message.text }}</span>
    </h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ANSWER_QUESTION } from '../../store/questions/types';
import icons from '../../enums/icons';
import colors from '../../enums/colors';

export default {
  name: 'true-or-false-question',
  props: {
    question: {
      type: Object,
      required: true
    },
    questionAnswered: {
      type: Boolean,
      required: true
    },
    onAnswerSelected: {
      type: Function
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      selectedAnswer: null,
      message: {
        text: '',
        color: ''
      }
    };
  },
  watch: {
    questionAnswered(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.answerQuestion();
      }
    },
    selectedAnswer() {
      this.onAnswerSelected();
    }
  },
  methods: {
    async answerQuestion() {
      const result = await this.$store.dispatch(ANSWER_QUESTION, {
        id: this.question.id,
        answer: this.selectedAnswer
      });

      if (result.correct === this.selectedAnswer) {
        this.message.text = this.$i18n.t('questions.correctAnswer');
        this.message.color = `${colors.green}--text`;
        this.message.icon = icons.checkCircle;
      } else {
        this.message.text = this.$i18n.t('questions.wrongAnswer');
        this.message.color = `${colors.red}--text`;
        this.message.icon = icons.cancel;
      }

      if (result.userStats) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('users.stats.success.gold', {
            value: result.userStats.diff.gold
          })
        });
      }
    }
  }
};
</script>
