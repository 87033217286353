<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light" v-if="reward.id">
        {{ $t('rewards.editReward') }}
      </span>
      <span class="text-h5 font-weight-light" v-else>
        {{ $t('rewards.createReward') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageRewardForm">
        <v-text-field
          solo
          v-model="reward.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
        ></v-text-field>
        <v-text-field
          solo
          :min="goldEnum.rewards.min"
          :max="goldEnum.rewards.max"
          :rules="[rules.required, rules.isInteger, rules.minMaxGold]"
          v-model="reward.gold"
          :label="$t('common.gold')"
          :hint="
            $t('common.minMaxValue', {
              min: goldEnum.rewards.min,
              max: goldEnum.rewards.max
            })
          "
        ></v-text-field>
        <h3>{{ $t('rewards.usabilityTitle') }}</h3>
        <v-radio-group v-model="reward.type" row @change="selectType">
          <v-radio
            :label="$t('rewards.constant')"
            :value="rewardTypes.constant"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('rewards.once')"
            :value="rewardTypes.once"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('rewards.times')"
            :value="rewardTypes.times"
            color="app-blue"
            class="mb-2"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="toggleTimesField"
          solo
          v-model.number="reward.timesToUse"
          :rules="[...rules.timesValidator()]"
          :min="minTimesToUse"
          :max="maxTimesToUse"
          :label="$t('rewards.howManyTimes')"
          :hint="
            $t('common.minMaxValue', {
              min: this.minTimesToUse,
              max: this.maxTimesToUse
            })
          "
        ></v-text-field>
      </v-form>
    </v-container>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../common/validators';
import { ADD_REWARD, UPDATE_REWARD } from '../../store/rewards/types';
import rewardTypes from '../../enums/reward.types';
import goldEnum from '../../enums/gold';

const MIN_TIMES_TO_USE = 1;
const MAX_TIMES_TO_USE = 100;

export default {
  name: 'manage-reward-modal',
  props: {
    selectedReward: Object
  },
  data() {
    return {
      reward: {
        name: '',
        gold: '',
        type: rewardTypes.constant,
        timesToUse: ''
      },
      rewardTypes,
      goldEnum,
      minTimesToUse: MIN_TIMES_TO_USE,
      maxTimesToUse: MAX_TIMES_TO_USE,
      toggleTimesField: false,
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.goldInteger')),
        minMaxGold: validators.minMaxValue(
          goldEnum.rewards.min,
          goldEnum.rewards.max,
          this.$i18n.t('common.minMaxValue', {
            min: goldEnum.rewards.min,
            max: goldEnum.rewards.max
          })
        ),
        timesValidator: () => {
          if (this.toggleTimesField) {
            return [
              validators.required(this.$i18n.t('common.requiredField')),
              validators.minMaxValue(
                MIN_TIMES_TO_USE,
                MAX_TIMES_TO_USE,
                this.$i18n.t('common.minMaxValue', {
                  min: MIN_TIMES_TO_USE,
                  max: MAX_TIMES_TO_USE
                })
              )
            ];
          }

          return true;
        }
      }
    };
  },
  created() {
    if (this.selectedReward) {
      this.reward = this.selectedReward;
    }

    if (this.reward.type === rewardTypes.times) {
      this.toggleTimesField = true;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageRewardForm.validate()) {
        return;
      }

      if (this.reward.id) {
        await this.$store.dispatch(UPDATE_REWARD, this.reward);
      } else {
        await this.$store.dispatch(ADD_REWARD, this.reward);
      }

      this.$emit('close');
    },
    selectType() {
      const { type } = this.reward;

      if (type === rewardTypes.times) {
        this.toggleTimesField = true;
        this.reward.timesToUse = '';
      } else {
        this.toggleTimesField = false;
      }
    }
  }
};
</script>
