<template>
  <div class="flip-card">
    <div class="flip-card-inner" v-bind:class="{ flipped: flipped }">
      <v-card
        :height="config.height"
        class="back cursor"
        @click="flipped = true"
        tile
        :ripple="false"
      >
        <v-container bg fill-height grid-list-md text-center class="pt-0">
          <v-row align="center">
            <v-col>
              <h3
                class="text-h4 font-weight-bold white--text letter-spacing-2 text-uppercase"
              >
                {{ $t('facts.pickFact') }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card :height="config.height" class="front" tile :ripple="false">
        <v-card-title class="card-header">
          <v-row align="center" justify="center">
            <v-avatar size="100">
              <div class="pa-3 bg-white">
                <v-img width="75" height="75" :src="bookImg"></v-img>
              </div>
            </v-avatar>
          </v-row>
        </v-card-title>
        <v-card-text class="board-card-content text-center pt-12">
          <v-row>
            <v-col cols="12">
              <h3 class="text-h5 app-blue--text pb-2">
                {{ $t('facts.didYouKnow') }}?
              </h3>
              <span class="font-weight-regular black--text" :class="textClass">
                {{ fact.text[currentUser.lang] }}
              </span>
            </v-col>
            <v-col cols="12" class="mt-2" v-if="fact.url">
              <a target="_blank" :href="fact.url" class="text-body-2">
                {{ $t('facts.learnMore') }}
              </a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-12">
          <v-btn color="app-blue" text block @click="$emit('close')">
            {{ $t('common.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import textSizeHelper from '../../common/text.size.helper';

export default {
  name: 'fact-modal',
  props: {
    fact: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    textClass() {
      return textSizeHelper.getTextClassByLength(
        this.fact.text[this.currentUser.lang].length
      );
    }
  },
  data() {
    return {
      flipped: false,
      bookImg: require('../../assets/images/book.svg')
    };
  }
};
</script>
