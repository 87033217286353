<template>
  <div>
    <v-card class="border-top-blue" tile>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="app-blue--text font-weight-light">
          {{ $t('tasks.tasks') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="app-blue"
          class="mr-0"
          @click="showArchivedTasks()"
        >
          <v-icon>mdi-archive</v-icon>
        </v-btn>
        <v-btn text icon @click="open()" color="app-blue" class="mr-0">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="py-2">
        <tasks-types-filter
          @onTasksFilterChange="onTasksFilterChange"
        ></tasks-types-filter>
      </div>
    </v-card>
    <div v-for="tasksList of tasksLists" :key="tasksList.name">
      <div v-if="filters[tasksList.name] || !hasSelectedFilters">
        <tasks-list
          :title="$t(tasksList.i18nKey)"
          :tasks="tasksList.tasks"
          :topBorderClass="tasksList.borderClass"
          :titleTextColorClass="tasksList.textClass"
        >
          <template #default="{ task }">
            <member-task
              v-if="task.group"
              :task="task"
              slot="task"
            ></member-task>
            <task v-else :task="task" slot="task"></task>
          </template>
        </tasks-list>
      </div>
    </div>
    <div id="archived-tasks">
      <tasks-list
        v-if="toggleArchivedTasks"
        topBorderClass="border-top-red"
        titleTextColorClass="app-red--text"
        :title="$t('tasks.archived')"
        :tasks="archivedTasks"
        :closeTasksPanel="closeArchivedTasks"
      >
        <template #default="{ task }">
          <member-task v-if="task.group" :task="task" slot="task"></member-task>
          <task v-else :task="task" slot="task"></task>
        </template>
      </tasks-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_TASKS,
  GET_ARCHIVED_TASKS,
  UPDATE_TASKS_TYPES_FILTER,
  RESET_TASKS_TYPES_FILTER
} from '../../store/tasks/types';
import TasksList from './TasksList';
import Task from './Task';
import MemberTask from '../group-members/tasks/MemberTask';
import TasksTypesFilter from './TasksTypesFilter';
import ManageTaskModal from './ManageTaskModal';
import taskTypes from '../../enums/task.types';
import tasksListsService from '../../services/tasks.lists.service';

const ARCHIVED_TASKS_SELECTOR = '#archived-tasks';

export default {
  name: 'tasks',
  components: {
    TasksList,
    TasksTypesFilter,
    Task,
    MemberTask
  },
  computed: {
    ...mapGetters([
      'todos',
      'dailies',
      'weeklies',
      'archivedTasks',
      'tasksTypesFilter'
    ]),
    hasSelectedFilters() {
      return this.tasksTypesFilter.some(f => f.selected);
    },
    filters() {
      return this.tasksTypesFilter.reduce((acc, filter) => {
        acc[filter.type] = filter.selected;
        return acc;
      }, {});
    },
    tasksLists() {
      return tasksListsService.getTasksListsConfiguration(
        this.todos,
        this.dailies,
        this.weeklies
      );
    }
  },
  data() {
    return {
      toggleArchivedTasks: false
    };
  },
  beforeDestroy() {
    this.$store.dispatch(RESET_TASKS_TYPES_FILTER);
  },
  methods: {
    open() {
      this.$root.$modal.show(ManageTaskModal, {
        selectedType: this.getSelectedFilterType()
      });
    },
    async showArchivedTasks() {
      this.toggleArchivedTasks = true;
      await this.$store.dispatch(GET_ARCHIVED_TASKS);
      this.$vuetify.goTo(ARCHIVED_TASKS_SELECTOR, { easing: 'easeOutQuint' });
    },
    closeArchivedTasks() {
      this.toggleArchivedTasks = false;
    },
    getSelectedFilterType() {
      const filter = this.tasksTypesFilter.find(f => f.selected);

      return filter ? filter.type : taskTypes.todo;
    },
    async onTasksFilterChange(type) {
      await this.$store.dispatch(UPDATE_TASKS_TYPES_FILTER, type);

      this.$store.dispatch(GET_TASKS, {
        archived: false,
        type: this.tasksTypesFilter.filter(f => f.selected).map(f => f.type)
      });
    }
  }
};
</script>
