<template>
  <v-card tile>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('rewards.generateRewards') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6">
      <p class="text-center">
        <strong>
          <span>{{ $t('rewards.generateRewardsCostText') }}</span>
          <v-chip color="app-blue" text-color="white" class="pl-2 gems-label">
            <gems-label
              :gems="generationCost"
              textColor="white---text"
            ></gems-label>
          </v-chip>
        </strong>
      </p>
      <v-form ref="generateRewardsForm">
        <v-text-field
          solo
          v-model="generationData.topic"
          :rules="[rules.required]"
          :label="$t('rewards.topic')"
        ></v-text-field>
        <v-text-field
          solo
          :min="generatorCountsEnum.rewards.min"
          :max="generatorCountsEnum.rewards.max"
          :rules="[rules.required, rules.minMaxRewardsCount]"
          v-model="generationData.rewardsCount"
          :label="$t('rewards.rewardsCountQuestion')"
          :hint="
            $t('common.minMaxValue', {
              min: generatorCountsEnum.rewards.min,
              max: generatorCountsEnum.rewards.max
            })
          "
        ></v-text-field>
        <v-row class="text-right">
          <v-col class="pt-0">
            <v-btn color="app-blue" dark @click="generateRewards()">
              {{ $t('rewards.generateRewards') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="generatedRewards.length > 0" class="mt-5">
        <generated-rewards-list
          :rewards="generatedRewards"
          @addRewards="addRewards"
        ></generated-rewards-list>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { GENERATE_REWARDS, ADD_MULTIPLE_REWARDS } from '@/store/rewards/types';
import GemsLabel from '../shared/GemsLabel';
import validators from '@/common/validators';
import generatorCountsEnum from '../../enums/generator.counts';
import GeneratedRewardsList from './GeneratedRewardsList';
import avatarStoreModalStatus from '../../enums/avatar.store.modal.status';

export default {
  name: 'generate-rewards-modal',
  components: {
    GemsLabel,
    GeneratedRewardsList
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    const { min, max } = generatorCountsEnum.rewards;

    return {
      loading: false,
      generatorCountsEnum,
      generatedRewards: [],
      generationCost: process.env.VUE_APP_OPENAI_CHATGPT_GENERATION_COST,
      generationData: {
        topic: '',
        rewardsCount: null
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        minMaxRewardsCount: validators.minMaxValue(
          min,
          max,
          this.$i18n.t('common.minMaxValue', {
            min,
            max
          })
        )
      }
    };
  },
  methods: {
    async generateRewards() {
      const { gems } = this.currentUser.stats;

      if (!this.$refs.generateRewardsForm.validate()) {
        return;
      }

      if (gems < this.generationCost) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('errors.notEnoughGemsToGenerateRewards')
        });

        this.$emit('close', { status: avatarStoreModalStatus.buyGems });
        return;
      }

      try {
        this.loading = true;

        this.generatedRewards = await this.$store.dispatch(
          GENERATE_REWARDS,
          this.generationData
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async addRewards(rewards) {
      try {
        this.loading = true;

        await this.$store.dispatch(ADD_MULTIPLE_REWARDS, {
          rewards: rewards.filter(reward => reward.selected)
        });

        this.loading = false;

        this.$emit('close');
      } catch (err) {
        this.loading = false;
      }
    }
  }
};
</script>
