<template>
  <v-container fluid class="px-0 pt-0 pb-1">
    <v-row>
      <v-col cols="12">
        <v-expansion-panels flat tile>
          <v-expansion-panel v-model="panel" class="border-top-blue">
            <v-expansion-panel-header>
              <h3 class="text-h6 font-weight-light app-blue--text">
                {{ $t('common.board') }}
              </h3>
              <template v-slot:actions>
                <v-btn
                  text
                  icon
                  color="app-blue"
                  class="mr-2"
                  @click.stop="changeBoard()"
                >
                  <v-icon class="rotation-off" color="app-blue">
                    mdi-swap-vertical
                  </v-icon>
                </v-btn>
                <v-btn text icon color="app-blue">
                  <v-icon color="app-blue">$vuetify.icons.expand</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="svg-container no-border">
                <v-card-text class="pa-0">
                  <object
                    id="board-wrapper"
                    type="image/svg+xml"
                    :data="boardSvg"
                    @load="onBoardLoaded"
                  ></object>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FactModal from '../facts/FactModal';
import SpecialBonusModal from '../special-bonuses/SpecialBonusModal';
import GamTaskModal from '../gam-tasks/GamTaskModal';
import QuestionModal from '../questions/QuestionModal';
import QuoteModal from '../quotes/QuoteModal';
import BonusGoldModal from '../bonuses/BonusGoldModal';
import BoardsModal from './BoardsModal';
import boardPositionTypes from '../../enums/board.position.types';
import commonVariables from '../../common/common.variables';
import { GET_SPECIAL_BOARD_POSITION } from '../../store/boards/types';
import localStorageService from '../../services/local.storage.service';

const BOARD_CARD_MODAL_CONFIG = {
  width: '360px'
};
const BOARD_CARD_CONFIG = {
  height: 460
};

export default {
  name: 'board',
  data() {
    const toggleBoard = JSON.parse(
      localStorageService.getItem(commonVariables.localStorageKeys.toggleBoard)
    );

    return {
      panel: [
        toggleBoard !== null && toggleBoard !== undefined ? toggleBoard : true
      ],
      boardPositionWatcher: null
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentBoard']),
    boardSvg() {
      return require(`../../assets/images/boards/${this.currentBoard.image}`);
    },
    figure() {
      // return require(`../../assets/images/figures/${this.currentUser.figure.image}`);

      if (
        this.currentUser.generatedAvatarUrl &&
        process.env.VUE_APP_IS_AVATAR_GENERATION_ENABLED
      ) {
        return this.currentUser.generatedAvatarUrl;
      } else if (this.currentUser.imageUrl) {
        return require(`../../assets/images/avatars/${this.currentUser.imageUrl}`);
      }

      return require('../../assets/images/blank-profile.png');
    }
  },
  created() {
    this.watchBoardCurrentPosition();
  },
  beforeDestroy() {
    this.boardPositionWatcher();
  },
  watch: {
    panel(newVal, oldVal) {
      if (newVal !== oldVal) {
        localStorageService.setItem(
          commonVariables.localStorageKeys.toggleBoard,
          newVal[0]
        );
      }
    }
  },
  methods: {
    async changeBoard() {
      await this.$root.$modal.show(
        BoardsModal,
        { buyMore: true },
        {
          width: 800
        }
      );
    },
    onBoardLoaded() {
      this.setCurrentPosition();
    },
    getPositions() {
      const objectElement = window.document.getElementById(
        commonVariables.board.boardWrapper
      );

      if (!objectElement || (objectElement && !objectElement.contentDocument)) {
        return [];
      }

      const positions = objectElement.contentDocument.getElementsByClassName(
        commonVariables.board.position
      );

      return positions;
    },
    getOldAndNewPositionElements(newVal, oldVal) {
      const positions = this.getPositions();
      let newPositionEl;
      let oldPositionEl;

      for (const position of positions) {
        const { dataset } = position;

        if (parseInt(dataset.pos) === parseInt(newVal)) {
          newPositionEl = position;
        } else if (parseInt(dataset.pos) === parseInt(oldVal)) {
          oldPositionEl = position;
        }
      }

      return { newPositionEl, oldPositionEl };
    },
    setCurrentPosition() {
      const { position } = this.currentUser.board;
      const positions = this.getPositions();

      for (const el of positions) {
        if (parseInt(el.dataset.pos) === parseInt(position)) {
          el.setAttribute(commonVariables.attributes.href, this.figure);
          break;
        }
      }
    },
    openCardModal(component, payload) {
      this.$root.$modal.show(
        component,
        { config: BOARD_CARD_CONFIG, ...payload },
        BOARD_CARD_MODAL_CONFIG
      );
    },
    openSpecialBoardModal(type, payload) {
      if (type === boardPositionTypes.fact) {
        this.openCardModal(FactModal, { fact: payload });
      } else if (type === boardPositionTypes.specialBonus) {
        this.openCardModal(SpecialBonusModal, { specialBonus: payload });
      } else if (type === boardPositionTypes.task) {
        this.openCardModal(GamTaskModal, { task: payload });
      } else if (type === boardPositionTypes.question) {
        this.openCardModal(QuestionModal, { question: payload });
      } else if (type === boardPositionTypes.quote) {
        this.openCardModal(QuoteModal, { quote: payload });
      } else {
        this.openCardModal(BonusGoldModal, { gold: payload.diff.gold });
      }
    },
    async getBoardSpecialPosition() {
      const { position } = this.currentUser.board;
      const boardPosition = this.currentBoard.positions[position];

      if (boardPosition.type === boardPositionTypes.none) return;

      const result = await this.$store.dispatch(
        GET_SPECIAL_BOARD_POSITION,
        boardPosition.type
      );

      if (!result.cardData) {
        return;
      }

      if (boardPosition.type === boardPositionTypes.random) {
        this.openSpecialBoardModal(result.cardType, result.cardData);
      } else {
        this.openSpecialBoardModal(boardPosition.type, result.cardData);
      }
    },
    watchBoardCurrentPosition() {
      this.boardPositionWatcher = this.$store.watch(
        (state, getters) => getters.currentUser.board.position,
        (newValue, oldValue) => {
          if (newValue === oldValue) return;

          const { newPositionEl, oldPositionEl } =
            this.getOldAndNewPositionElements(newValue, oldValue);

          if (newPositionEl && oldPositionEl) {
            newPositionEl.setAttribute(
              commonVariables.attributes.href,
              this.figure
            );
            oldPositionEl.setAttribute(commonVariables.attributes.href, '');
          }

          this.getBoardSpecialPosition();
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel
  > .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon.rotation-off {
  transform: none !important;
}
</style>
