<template>
  <div class="flip-card">
    <div class="flip-card-inner" v-bind:class="{ flipped: flipped }">
      <v-card
        :height="config.height"
        class="back cursor"
        @click="flipped = true"
        tile
        :ripple="false"
      >
        <v-container bg fill-height grid-list-md text-center class="pt-0">
          <v-row align="center">
            <v-col>
              <h3
                class="text-h4 font-weight-bold white--text letter-spacing-2 text-uppercase"
              >
                {{ $t('gamTasks.pickTask') }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card :height="config.height" class="front" tile :ripple="false">
        <v-card-title class="card-header">
          <v-row align="center" justify="center" class="pa-1">
            <v-avatar size="100">
              <div class="checklist-icon bg-white rounded">
                <v-img width="80" height="80" :src="checklistImg"></v-img>
              </div>
            </v-avatar>
          </v-row>
        </v-card-title>
        <v-card-text class="board-card-content pt-12">
          <v-card class="pa-2 border-top-blue mt-3" tile>
            <v-chip small label color="app-green" class="ml-0 mb-2">
              <span class="text-body-2 white--text font-weight-medium px-2">
                {{ $t(`tasks.${task.type}`) }}
              </span>
            </v-chip>
            <h3 class="font-weight-regular pb-1" :class="textClass">
              {{ task.title[currentUser.lang] }}
            </h3>
            <p v-if="task.notes[currentUser.lang]" class="font-weight-regular">
              {{ task.notes[currentUser.lang] }}
            </p>

            <v-card-actions class="pa-0">
              <v-chip
                v-for="tag of task.tags"
                :key="tag.id"
                dark
                small
                color="app-blue"
              >
                {{ $t(`tags.${tag.name}`) }}
              </v-chip>
              <v-spacer></v-spacer>
              <gold-label
                :gold="task.gold"
                :text-color="goldColorText"
              ></gold-label>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-actions class="mt-12 justify-space-around">
          <v-btn color="app-red" text @click="$emit('close')">
            {{ $t('common.refuse') }}
          </v-btn>
          <v-btn color="app-blue" text @click="save()">
            {{ $t('gamTasks.letsDoIt') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tasksGoldColorMixin from '../../mixins/task.gold.color.mixin';
import { ADD_TASK } from '@/store/tasks/types';
import textSizeHelper from '../../common/text.size.helper';
import GoldLabel from '../shared/GoldLabel';

export default {
  name: 'gam-task-modal',
  components: {
    GoldLabel
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [tasksGoldColorMixin],
  computed: {
    ...mapGetters(['currentUser']),
    textClass() {
      return textSizeHelper.getTextClassByLength(
        this.task.title[this.currentUser.lang].length
      );
    }
  },
  data() {
    return {
      height: 460,
      flipped: false,
      checklistImg: require('../../assets/images/checklist.svg')
    };
  },
  methods: {
    async save() {
      await this.$store.dispatch(ADD_TASK, {
        title: this.task.title[this.currentUser.lang],
        notes: this.task.notes[this.currentUser.lang],
        gold: this.task.gold,
        type: this.task.type,
        tags: this.task.tags.map(tag => tag.id)
      });

      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.checklist-icon {
  padding: 16px 12px 16px 24px;
}
</style>
