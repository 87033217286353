<template>
  <v-card tile class="border-top-orange">
    <v-toolbar color="white" flat>
      <v-toolbar-title class="app-orange--text font-weight-light">
        {{ $t('missions.mainMission') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="hasMissions">
        <template v-slot:activator="{ on }">
          <v-btn icon color="app-orange" class="ma-0 ml-1" v-on="on">
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="mission of missions"
            :key="mission.id"
            @click="changeCurrentMainMission(mission)"
          >
            <mission-small-status-label
              :status="mission.status"
            ></mission-small-status-label>
            <v-list-item-title class="ml-1">
              {{ mission.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon color="app-orange" class="mr-0" @click="add()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import MissionSmallStatusLabel from './MissionSmallStatusLabel';
import ManageMissionModal from './ManageMissionModal';
import {
  ADD_MISSION_DASHBOARD,
  SET_CURRENT_MISSION,
  SET_MISSION_AS_MAIN
} from '@/store/missions/types';
import { RESET_MISSION_TASKS_TYPES_FILTER } from '../../store/missions/tasks/types';
import missionStatuses from '../../enums/mission.statuses';

export default {
  name: 'missions-toolbar',
  components: {
    MissionSmallStatusLabel
  },
  props: {
    missions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasMissions() {
      return this.missions && this.missions.length > 0;
    }
  },
  methods: {
    async add() {
      const payload = await this.$root.$modal.show(ManageMissionModal);

      if (!payload) return;

      const result = await this.$store.dispatch(ADD_MISSION_DASHBOARD, payload);

      if (result.status === missionStatuses.active) {
        this.changeCurrentMainMission(result, false);
      }

      this.$notify({
        type: 'success',
        text: this.$i18n.t('missions.missionAdded')
      });
    },
    async changeCurrentMainMission(mission, sendRequest = true) {
      this.$store.dispatch(RESET_MISSION_TASKS_TYPES_FILTER);

      if (!mission.isMain) {
        await this.$store.dispatch(SET_MISSION_AS_MAIN, {
          mission,
          sendRequest
        });
      } else {
        this.$store.dispatch(SET_CURRENT_MISSION, mission);
      }
    }
  }
};
</script>
