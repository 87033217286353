<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular" v-if="task.id">
        {{ $t(`tasks.edit.${task.type}`) }}
      </span>
      <span class="text-h5 font-weight-regular" v-else>
        {{ $t('tasks.createTask') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="createEditTaskForm">
        <v-text-field
          solo
          v-model="task.title"
          :rules="[rules.required]"
          :label="$t('common.title')"
          :disabled="task.completed"
        ></v-text-field>
        <v-textarea
          solo
          v-model="task.notes"
          :label="$t('common.notes')"
          :disabled="task.completed"
        ></v-textarea>
        <v-text-field
          solo
          :min="goldEnum.minGold"
          :max="goldEnum.maxGold"
          :rules="[rules.required, rules.isInteger, rules.minMaxGold]"
          v-model="task.gold"
          :label="$t('common.gold')"
          :hint="
            $t('common.minMaxValue', {
              min: goldEnum.minGold,
              max: goldEnum.maxGold
            })
          "
          :disabled="task.completed"
        ></v-text-field>
        <v-select
          v-model="task.tags"
          :items="mappedTags"
          :rules="[rules.maxArrayLength]"
          chips
          :label="$t('tags.tags')"
          item-value="id"
          item-text="name"
          multiple
          solo
          class="tags-select"
          :disabled="task.completed"
        >
          <template #selection="{ item }">
            <v-chip color="app-blue">{{ item.name }}</v-chip>
          </template>
        </v-select>
        <v-switch
          v-if="!task.completed"
          class="mt-0 mb-4"
          v-model="task.requireResult"
          :label="$t('tasks.requireResultWhenComplete')"
          color="app-blue"
          hide-details
        ></v-switch>
        <div v-else-if="task.completed && task.result" class="mb-5">
          <h3 class="font-weight-bold black--text">
            {{ $t('tasks.resultOfTheTask') }}:
          </h3>
          <span>{{ task.result }}</span>
        </div>
        <h3>{{ $t('tasks.type') }}</h3>
        <v-radio-group v-model="task.type" row :disabled="task.completed">
          <v-radio
            :label="$t('tasks.todo')"
            :value="taskTypes.todo"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('tasks.daily')"
            :value="taskTypes.daily"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('tasks.weekly')"
            :value="taskTypes.weekly"
            color="app-blue"
            class="mb-2"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()" :disabled="task.completed">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '@/common/validators';
import { mapGetters } from 'vuex';
import {
  ADD_TASK,
  UPDATE_TASK,
  CHANGE_TASKS_TYPES_FILTER
} from '@/store/tasks/types';
import taskTypes from '@/enums/task.types';
import goldEnum from '../../enums/gold';

const MIN_TAGS = 0;
const MAX_TAGS = 3;

export default {
  name: 'create-edit-task-modal',
  props: {
    selectedTask: Object,
    selectedType: {
      type: String,
      default: taskTypes.todo
    },
    shouldUpateFilter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['tags']),
    mappedTags() {
      return this.tags.map(tag => ({
        id: tag.id,
        name: this.$i18n.t(`tags.${tag.name}`)
      }));
    }
  },
  data() {
    return {
      taskTypes,
      goldEnum,
      task: {
        title: '',
        notes: '',
        gold: '',
        completed: false,
        type: this.selectedType,
        tags: [],
        requireResult: false
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.goldInteger')),
        minMaxGold: validators.minMaxValue(
          goldEnum.minGold,
          goldEnum.maxGold,
          this.$i18n.t('common.minMaxValue', {
            min: goldEnum.minGold,
            max: goldEnum.maxGold
          })
        ),
        maxArrayLength: validators.minMaxArrayLength(
          MIN_TAGS,
          MAX_TAGS,
          this.$i18n.t('tasks.maxTagsLength', { number: MAX_TAGS })
        )
      }
    };
  },
  created() {
    this.populateTask();
  },
  methods: {
    populateTask() {
      if (this.selectedTask) {
        this.task = {
          ...this.selectedTask,
          tags: this.selectedTask.tags.map(tag => tag.id)
        };
      }
    },
    async save() {
      if (!this.$refs.createEditTaskForm.validate()) {
        return;
      }

      if (this.task.id) {
        await this.$store.dispatch(UPDATE_TASK, this.task);
      } else {
        await this.$store.dispatch(ADD_TASK, this.task);
      }

      if (this.shouldUpateFilter) {
        this.$store.dispatch(CHANGE_TASKS_TYPES_FILTER, this.task.type);
      }

      this.$emit('close', this.task);
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/styles/colors';

.tags-select {
  .v-chip {
    color: $white;
    background: $app-blue;
  }
}
</style>
