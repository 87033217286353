const TEXT_CLASSES = {
  headline: 'headline',
  title: 'title',
  subtitle: 'text-subtitle-1',
  body2: 'body-2'
};

const TEXT_LENGTH_RANGES = {
  xsmall: {
    min: 0,
    max: 50
  },
  small: {
    min: 51,
    max: 250
  },
  medium: {
    min: 251,
    max: 400
  },
  large: {
    min: 401
  }
};

function getTextClassByLength(length) {
  if (
    length >= TEXT_LENGTH_RANGES.xsmall.min &&
    length <= TEXT_LENGTH_RANGES.xsmall.max
  ) {
    return TEXT_CLASSES.headline;
  } else if (
    length >= TEXT_LENGTH_RANGES.small.min &&
    length <= TEXT_LENGTH_RANGES.small.max
  ) {
    return TEXT_CLASSES.title;
  } else if (
    length >= TEXT_LENGTH_RANGES.medium.min &&
    length <= TEXT_LENGTH_RANGES.medium.max
  ) {
    return TEXT_CLASSES.subtile;
  }

  return TEXT_CLASSES.body2;
}

export default {
  getTextClassByLength
};
