<template>
  <div class="generated-rewards-list">
    <v-switch
      class="ml-1"
      v-model="allSelected"
      inset
      :label="$t('common.selectAll')"
      color="app-green"
      @change="toggleAll()"
    ></v-switch>
    <generated-reward
      class="mb-3"
      v-for="reward of rewards"
      :key="reward.name"
      :reward="reward"
      @toggleReward="toggleReward()"
    ></generated-reward>
    <div class="text-right">
      <v-btn
        v-if="hasSelectedRewards"
        color="app-blue"
        dark
        @click="addRewards()"
      >
        {{ $t('rewards.addRewards') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import GeneratedReward from './GeneratedReward';

export default {
  name: 'generated-rewards-list',
  components: {
    GeneratedReward
  },
  props: {
    rewards: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allSelected: true
    };
  },
  computed: {
    hasSelectedRewards() {
      return this.rewards.some(reward => reward.selected);
    }
  },
  methods: {
    toggleAll() {
      this.rewards.forEach(reward => {
        reward.selected = this.allSelected;
      });
    },
    toggleReward() {
      this.allSelected = !this.rewards.some(reward => !reward.selected);
    },
    addRewards() {
      this.$emit('addRewards', this.rewards);
    }
  }
};
</script>
