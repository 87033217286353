<template>
  <v-card class="pa-2" tile :ripple="false">
    <v-container class="pa-0 pt-3">
      <v-row align="center" justify="center">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="reward.selected"
            text
            icon
            large
            color="app-green"
            @click.stop="toggleReward()"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            icon
            large
            color="app-green"
            @click.stop="toggleReward()"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="pt-0 pl-md-0">
          <v-card-text class="text-subtitle-1 pl-3">
            <span class="one-line">{{ reward.name }}</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-1 pb-0">
      <reward-type-label :reward="reward"></reward-type-label>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <gold-label :gold="reward.gold"></gold-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import RewardTypeLabel from './RewardTypeLabel';
import GoldLabel from '../shared/GoldLabel';

export default {
  name: 'generated-reward',
  components: {
    RewardTypeLabel,
    GoldLabel
  },
  props: {
    reward: Object
  },
  methods: {
    async toggleReward() {
      this.reward.selected = !this.reward.selected;

      this.$emit('toggleReward');
    }
  }
};
</script>
