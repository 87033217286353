<template>
  <v-card class="border-top-red" tile>
    <v-toolbar color="white" flat>
      <v-toolbar-title class="app-red--text font-weight-light">
        {{ $t('rewards.rewards') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="app-red" text icon @click="openGenerateRewards()">
        <v-icon>mdi-creation</v-icon>
      </v-btn>
      <v-btn text icon @click="open()" color="app-red" class="mr-0">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="pt-5">
      <div v-if="rewards.length > 0">
        <v-row>
          <v-col
            cols="12"
            v-for="reward in rewards"
            :key="reward.id"
            class="mb-2"
          >
            <member-reward v-if="reward.group" :reward="reward"></member-reward>
            <reward v-else :reward="reward"></reward>
          </v-col>
        </v-row>
      </div>
      <div v-else class="text-center">
        <span class="text-subtitle-1 font-weight-light">
          {{ $t('rewards.noRewardsFound') }}
        </span>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageRewardModal from './ManageRewardModal';
import Reward from './Reward';
import MemberReward from '../group-members/rewards/MemberReward';
import GenerateRewardsModal from './GenerateRewardsModal';
import BuyGemsModal from '../store/BuyGemsModal';

export default {
  name: 'rewards',
  components: {
    Reward,
    MemberReward
  },
  computed: {
    ...mapGetters(['rewards'])
  },
  methods: {
    open() {
      this.$root.$modal.show(ManageRewardModal);
    },
    async openGenerateRewards() {
      const payload = await this.$root.$modal.show(GenerateRewardsModal);

      if (payload?.status) {
        this.$root.$modal.show(BuyGemsModal);
      }
    }
  }
};
</script>
