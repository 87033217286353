<template>
  <div>
    <v-container fluid v-if="!loading" class="pa-4 mb-10">
      <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
        <v-col cols="12">
          <user-profile-card></user-profile-card>
          <div class="text-center mt-3 mb-2">
            <support-us></support-us>
          </div>
          <div class="text-center">
            <feedback></feedback>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <board v-if="currentUser.id"></board>
        </v-col>
      </v-row>
      <v-row id="dashboard-items" class="mt-0">
        <v-col md="4" sm="12" cols="12" v-if="visible(0)">
          <missions-dashboard></missions-dashboard>
        </v-col>
        <v-col md="4" sm="12" cols="12" v-if="visible(1)">
          <tasks></tasks>
        </v-col>
        <v-col md="4" sm="12" cols="12" v-if="visible(2)">
          <rewards></rewards>
        </v-col>
      </v-row>
    </v-container>
    <bottom-dashboard-nav v-if="$vuetify.breakpoint.smAndDown">
    </bottom-dashboard-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfileCard from '@/components/users/UserProfileCard';
import Board from '@/components/boards/DashboardBoard';
import MissionsDashboard from '@/components/missions/MissionsDashboard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import Tasks from '@/components/tasks/Tasks';
import Rewards from '@/components/rewards/Rewards';
import BottomDashboardNav from '../components/shared/BottomDashboardNav';
import { GET_CURRENT_USER_DASHBOARD } from '../store/dashboard/types';
import dailyCronMixin from '../mixins/daily.cron.mixin';

export default {
  name: 'dashboard',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    Board,
    MissionsDashboard,
    Tasks,
    Rewards,
    BottomDashboardNav
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters(['bottomNavItemsVisibility', 'currentUser']),
    toggleBottomNav() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      dailyCronWatcher: null,
      loading: true
    };
  },
  async created() {
    this.watchDailyCron();
    await this.loadData();

    this.loading = false;
  },
  beforeDestroy() {
    this.dailyCronWatcher();
  },
  methods: {
    loadData() {
      return this.$store.dispatch(GET_CURRENT_USER_DASHBOARD);
    },
    visible(index) {
      if (!this.$vuetify.breakpoint.smAndDown) {
        return true;
      }

      return this.bottomNavItemsVisibility[index];
    },
    watchDailyCron() {
      this.dailyCronWatcher = this.$store.watch(
        (state, getters) => getters.cronData,
        cronData => {
          if (!cronData) return;

          this.loadData();
        }
      );
    }
  }
};
</script>
