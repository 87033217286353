<template>
  <v-chip color="app-blue" dark small class="tag">
    <span
      v-if="reward.type === rewardTypes.times"
      class="font-weight-bold text-caption"
    >
      x {{ reward.timesToUse }}
    </span>
    <span v-else-if="reward.type === rewardTypes.constant" class="text-caption">
      <i class="fas fa-infinity"></i>
    </span>
    <span v-else class="font-weight-bold text-caption"> x 1 </span>
  </v-chip>
</template>

<script>
import rewardTypes from '../../enums/reward.types';

export default {
  name: 'reward-type-label',
  props: {
    reward: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rewardTypes
    };
  }
};
</script>
