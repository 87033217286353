<template>
  <div>
    <h3 class="subtitle text-center mt-3 font-weight-bold black--text">
      {{ question.text[currentUser.lang] }}
    </h3>
    <v-card class="pa-2 mb-1 mt-4 cursor" @click="selectAnswer('a')" tile>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-icon :color="question.answers.a.color">
            {{ question.answers.a.icon }}
          </v-icon>
        </v-col>
        <v-col cols="10">
          <span class="text-body-1">
            {{ question.answers.a.text[currentUser.lang] }}
          </span>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-2 mb-1 cursor" @click="selectAnswer('b')">
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-icon :color="question.answers.b.color">
            {{ question.answers.b.icon }}
          </v-icon>
        </v-col>
        <v-col cols="10">
          <span class="text-body-1">
            {{ question.answers.b.text[currentUser.lang] }}
          </span>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-2 mb-1 cursor" @click="selectAnswer('c')">
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-icon :color="question.answers.c.color">
            {{ question.answers.c.icon }}
          </v-icon>
        </v-col>
        <v-col cols="10">
          <span class="text-body-1">
            {{ question.answers.c.text[currentUser.lang] }}
          </span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ANSWER_QUESTION } from '../../store/questions/types';
import icons from '../../enums/icons';
import colors from '../../enums/colors';

export default {
  name: 'question-with-answer',
  props: {
    question: {
      type: Object,
      required: true
    },
    questionAnswered: {
      type: Boolean,
      required: true
    },
    onAnswerSelected: {
      type: Function
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      selectedAnswer: ''
    };
  },
  created() {
    this.mapAnswers();
  },
  watch: {
    questionAnswered(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.answerQuestion();
      }
    }
  },
  methods: {
    mapAnswers() {
      for (const key of Object.keys(this.question.answers)) {
        this.mapSingleAnswer(key);
      }
    },
    mapSingleAnswer(answer) {
      this.question.answers[answer] = {
        text: this.question.answers[answer].text,
        color: colors.green,
        icon: icons.radioButtonUnchecked
      };
    },
    selectAnswer(answer) {
      if (this.selectedAnswer === answer || this.questionAnswered) return;

      this.onAnswerSelected();

      if (this.selectedAnswer) {
        this.mapSingleAnswer(this.selectedAnswer);
      }

      this.selectedAnswer = answer;
      this.question.answers[answer].icon = icons.radioButtonChecked;
    },
    async answerQuestion() {
      const result = await this.$store.dispatch(ANSWER_QUESTION, {
        id: this.question.id,
        answer: this.selectedAnswer
      });

      const correctAnswer = this.question.answers[result.correct];
      const selected = this.question.answers[this.selectedAnswer];

      if (result.correct === this.selectedAnswer) {
        correctAnswer.icon = icons.checkCircle;
      } else {
        selected.icon = icons.cancel;
        selected.color = colors.red;
        correctAnswer.icon = icons.checkCircle;
      }

      if (result.userStats) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('users.stats.success.gold', {
            value: result.userStats.diff.gold
          })
        });
      }
    }
  }
};
</script>
