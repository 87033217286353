<template>
  <v-card class="cursor" @click="editReward" tile :ripple="false">
    <v-btn
      color="app-red"
      dark
      absolute
      text
      small
      class="top-right-corner"
      icon
      @click.stop="deleteReward(reward.id)"
    >
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
    <div class="d-flex align-center pt-2">
      <v-card-text class="text-subtitle-1 pl-3">
        <span class="one-line">{{ reward.name }}</span>
      </v-card-text>
      <div class="d-flex align-center pr-2">
        <gold-label :gold="reward.gold"></gold-label>
      </div>
    </div>
    <v-card-actions class="pt-0 pb-2">
      <reward-type-label :reward="reward"></reward-type-label>
      <v-spacer></v-spacer>
      <v-btn
        small
        dark
        color="app-green"
        depressed
        rounded
        @click.stop="redeem"
      >
        <span class="font-weight-medium">
          {{ $t('rewards.redeem') }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageRewardModal from './ManageRewardModal';
import RewardTypeLabel from './RewardTypeLabel';
import GoldLabel from '../shared/GoldLabel';
import ConfirmModal from '../shared/ConfirmModal';
import { REDEEM_REWARD, DELETE_REWARD } from '../../store/rewards/types';

export default {
  name: 'reward',
  components: {
    RewardTypeLabel,
    GoldLabel
  },
  props: {
    reward: Object
  },
  methods: {
    async redeem() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$root.$t('rewards.redeemRewardConfirm')
      });

      if (confirmed) {
        await this.redeemReward();
      }
    },
    async redeemReward() {
      await this.$store.dispatch(REDEEM_REWARD, { ...this.reward });

      this.$notify({
        type: 'info',
        text: this.$root.$t('rewards.redeemedReward', {
          gold: this.reward.gold
        })
      });
    },
    editReward() {
      this.$root.$modal.show(ManageRewardModal, {
        selectedReward: { ...this.reward }
      });
    },
    async deleteReward(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$root.$t('rewards.deleteRewardConfirm')
      });

      if (confirmed) {
        this.$store.dispatch(DELETE_REWARD, id);
      }
    }
  }
};
</script>
