<template>
  <div>
    <missions-toolbar :missions="missions"></missions-toolbar>
    <div class="mt-2" v-if="hasMissions">
      <v-card>
        <member-mission
          v-if="isGroupMission"
          :isDetailPage="true"
          :mission="currentMission"
          top-border-class="no-border"
        ></member-mission>
        <dashboard-mission-card
          v-else
          top-border-class="no-border"
          :isDetailPage="true"
          :mission="currentMission"
          :update="update"
          :del="del"
        >
        </dashboard-mission-card>
        <div class="py-2 px-3" depressed v-if="canCompleteMission">
          <v-btn dark color="app-blue" block @click="completeMission()" tile>
            {{ $t('missions.completeMissionBtn') }}
          </v-btn>
        </div>
      </v-card>
      <div class="mt-2">
        <group-mission-tasks
          v-if="isGroupMission"
          :mission="currentMission"
          top-border-class="no-border"
          :title="$t('missions.tasks')"
        ></group-mission-tasks>
        <mission-tasks
          v-else
          :mission="currentMission"
          top-border-class="no-border"
          :title="$t('missions.tasks')"
        ></mission-tasks>
      </div>
    </div>
    <v-card v-else class="text-center mt-2 py-1" tile>
      <span class="text-subtitle-1 font-weight-light">
        {{ $t('missions.noMainMissionFound') }}
      </span>
    </v-card>
  </div>
</template>

<script>
import MissionsToolbar from './MissionsToolbar';
import DashboardMissionCard from './DashboardMissionCard';
import MissionTasks from '../mission-tasks/MissionTasks';
import MemberMission from '../group-members/missions/MemberMission';
import GroupMissionTasks from '../groups/missions/tasks/GroupMissionTasks';
import {
  SET_CURRENT_MISSION,
  UPDATE_CURRENT_MISSION,
  COMPLETE_MISSION,
  SET_MISSION_AS_MAIN,
  UPDATE_MISSION,
  DELETE_MISSION
} from '@/store/missions/types';
import { RESET_MISSION_TASKS_TYPES_FILTER } from '../../store/missions/tasks/types';
import { COMPLETE_GROUP_MISSION } from '../../store/missions/types';
import { mapGetters, mapMutations } from 'vuex';
import missionsMapper from '../../mappers/missions.mapper';
import missionStatuses from '../../enums/mission.statuses';
import multiNotificationsMixin from '../../mixins/multi.notifications.mixin';

export default {
  name: 'missions-dashboard',
  components: {
    MissionsToolbar,
    DashboardMissionCard,
    MemberMission,
    MissionTasks,
    GroupMissionTasks
  },
  mixins: [multiNotificationsMixin],
  computed: {
    ...mapGetters(['missions', 'currentMission']),
    canCompleteMission() {
      return (
        this.currentMission.id &&
        this.currentMission.status === missionStatuses.active &&
        this.currentMission.tasks.length > 0 &&
        this.currentMission.tasks.filter(t => !t.completed).length === 0
      );
    },
    hasMissions() {
      return this.missions.length > 0;
    },
    isGroupMission() {
      return this.currentMission.group && this.currentMission.group.id;
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      updateMissionMutation: UPDATE_MISSION,
      deleteMissionMutation: DELETE_MISSION
    }),
    init() {
      if (this.missions.length > 0) {
        this.setCurrentSavedMainMission();
      }
    },
    setCurrentSavedMainMission() {
      const mainMission = this.missions.find(m => m.isMain);

      if (mainMission) {
        this.changeCurrentMainMission(mainMission);
      } else {
        this.changeCurrentMainMission(this.missions[0]);
      }
    },
    async changeCurrentMainMission(mission, sendRequest = true) {
      this.$store.dispatch(RESET_MISSION_TASKS_TYPES_FILTER);

      if (!mission.isMain) {
        await this.$store.dispatch(SET_MISSION_AS_MAIN, {
          mission,
          sendRequest
        });
      } else {
        this.$store.dispatch(SET_CURRENT_MISSION, mission);
      }
    },
    async update(payload) {
      const missionToUpdate = missionsMapper.getMissionUpdateReqBody(payload);

      await this.$store.dispatch(UPDATE_CURRENT_MISSION, missionToUpdate);
      this.updateMissionMutation(missionToUpdate);
    },
    async completePersonalMission() {
      const { diff } = await this.$store.dispatch(
        COMPLETE_MISSION,
        this.currentMission.id
      );

      this.notifySuccess(diff, 'users.stats.success');

      this.deleteMissionMutation(this.currentMission.id);
      this.init();
    },
    async completeGroupMission() {
      const { diff } = await this.$store.dispatch(
        COMPLETE_GROUP_MISSION,
        this.currentMission
      );

      if (diff) {
        this.notifySuccess(diff, 'users.stats.success');
        this.deleteMissionMutation(this.currentMission.id);
        this.init();
      }
    },
    async completeMission() {
      if (this.isGroupMission) {
        this.completeGroupMission();
      } else {
        this.completePersonalMission();
      }
    },
    async del(id) {
      await this.$store.dispatch(DELETE_MISSION, id);
      this.init();
    }
  }
};
</script>
