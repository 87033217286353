<template>
  <div class="flip-card">
    <div class="flip-card-inner" v-bind:class="{ flipped: flipped }">
      <v-card
        :height="config.height"
        class="back cursor"
        @click="flipped = true"
        tile
        :ripple="false"
      >
        <v-container bg fill-height grid-list-md text-center class="pt-0">
          <v-row align="center">
            <v-col>
              <h3
                class="text-h4 font-weight-bold white--text letter-spacing-2 text-uppercase"
              >
                {{ $t('questions.pickQuestion') }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card :height="config.height" class="front" tile :ripple="false">
        <v-card-title class="card-header">
          <v-row align="center" justify="center" class="pa-1">
            <v-avatar size="100">
              <div class="pa-3 bg-white rounded">
                <v-img width="80" height="80" :src="questionImg"></v-img>
              </div>
            </v-avatar>
          </v-row>
        </v-card-title>
        <v-card-text class="board-card-content pt-0 mt-10">
          <true-or-false-question
            v-if="question.type === questionTypes.trueOrFalse"
            :question="question"
            :question-answered="questionAnswered"
            :on-answer-selected="onAnswerSelected"
            class="mt-2"
          ></true-or-false-question>
          <question-with-answers
            v-else
            :question="question"
            :question-answered="questionAnswered"
            :on-answer-selected="onAnswerSelected"
            class="mt-2"
          ></question-with-answers>
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn
            v-if="!questionAnswered"
            color="app-blue"
            text
            block
            @click="submitAnswer()"
            :disabled="!answerSelected"
          >
            {{ $t('questions.submitAnswer') }}
          </v-btn>
          <v-btn v-else color="app-blue" text block @click="$emit('close')">
            {{ $t('common.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import TrueOrFalseQuestion from './TrueOrFalseQuestion';
import QuestionWithAnswers from './QuestionWithAnswers';
import questionTypes from '../../enums/question.types';

export default {
  name: 'question-modal',
  components: {
    TrueOrFalseQuestion,
    QuestionWithAnswers
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      flipped: false,
      questionTypes,
      questionAnswered: false,
      answerSelected: false,
      questionImg: require('../../assets/images/question.svg')
    };
  },
  methods: {
    onAnswerSelected() {
      this.answerSelected = true;
    },
    async submitAnswer() {
      this.questionAnswered = true;
    }
  }
};
</script>
