<template>
  <v-card :height="config.height" tile :ripple="false">
    <v-card-title class="card-header">
      <v-row align="center" justify="center" class="pa-1">
        <v-avatar size="100">
          <div class="pa-3 bg-white">
            <v-img width="80" height="80" :src="confetti"></v-img>
          </div>
        </v-avatar>
      </v-row>
    </v-card-title>
    <v-card-text class="board-card-content text-center pt-10">
      <h1 class="text-h5 mt-4 pt-3 app-blue--text font-weight-medium">
        {{ $t('common.congratulations') }}!
      </h1>
      <p class="text-subtitle-1 pt-2">
        {{ $t('common.gainedBonusGold') }}
      </p>
      <div class="mt-4">
        <v-chip color="app-green" text-color="white" class="text-h6 pa-1 px-2">
          <v-icon class="mr-2"> $vuetify.icons.gold </v-icon>
          <span>+{{ gold }}</span>
        </v-chip>
      </div>
    </v-card-text>
    <v-card-actions class="pt-12">
      <v-btn color="app-blue" text block @click="$emit('close')">
        {{ $t('common.continue') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'bonus-gold-modal',
  props: {
    gold: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      confetti: require('../../assets/images/confetti.svg')
    };
  }
};
</script>
